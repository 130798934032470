<template>
    <div>
        <main style="margin-top: 76px;">
            <div class="container path pt-3 mb-4">
                <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item">
                            <a class="text-decoration-none text-morado fw-medium" @click="goHome()" style="cursor: pointer;">
                                <i class="fas fa-home-alt text-morado"></i> Inicio</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a class="text-decoration-none text-morado fw-medium" @click="goPedidos()" style="cursor: pointer;">
                                Pedidos
                            </a>
                        </li>
                        <li class="breadcrumb-item active fw-medium" aria-current="page">Detalle pedido</li>
                    </ol>
                </nav>
            </div>
            <hr>
            <div class="page-content pt-150 pb-150 mb-4">
                <div class="container">
                    <CLoader :pConfigLoad="ConfigLoad">
                        <template slot="BodyFormLoad">

                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-6">
                                            <h5 class="card-title">Pedido# {{ objPedido.Folio }} </h5>
                                        </div>
                                        <div class="col-6 text-end">
                                            <a href="#" class="btn btn-primary btn-sm" @click="goPedidos()">
                                                <i class="fas fa-arrow-left"></i> Regresar
                                            </a>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <p class="card-text">
                                            Estatus: 
                                            <span class="badge text-bg-primary" v-if="objPedido.Estatus == 'Pendiente'">{{objPedido.Estatus}}</span>
                                            <span class="badge text-bg-info"    v-if="objPedido.Estatus == 'Pagado'">{{objPedido.Estatus}}</span>
                                            <span class="badge text-bg-warning" v-if="objPedido.Estatus == 'Procesando'">{{objPedido.Estatus}}</span>
                                            <span class="badge text-bg-success" v-if="objPedido.Estatus == 'Completado'">{{objPedido.Estatus}}</span>
                                            <span class="badge text-bg-danger"  v-if="objPedido.Estatus == 'Cancelado'">{{objPedido.Estatus}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div v-if="objPedido.Estatus == 'Cancelado'" class="row mt-4">
                                <div class="col-12">
                                    <div class="alert alert-danger" role="alert">
                                        <h4 class="alert-heading">Motivo de cancelación</h4>
                                        <p>{{ objPedido.MotoCancelacion }}</p>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-4">
                                <div class="row row-cols-1 row-cols-md-3 row-cols-xl-3" style="margin-left: 0.15rem !important;">
                                    <div class="col mb-3">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <h5 class="card-title">Metodo de pago</h5>

                                                <div class="product-added">
                                                    <figure class="img-thumbnail" style="border:none;">
                                                        <i v-if="metodoPago.IdMetodoPago == 1" class="fad fa-credit-card fa-lg" style="font-size: 1.8em !important;"></i>
                                                        <i v-if="metodoPago.IdMetodoPago == 3" class="fad fa-money-bill fa-lg" style="font-size: 1.8em !important;"></i>
                                                        <i v-if="metodoPago.IdMetodoPago == 4" class="fad fa-university fa-lg" style="font-size: 1.8em !important;"></i>
                                                    </figure>
                                                    
                                                    <div class="details">
                                                        <h3 class="name"> {{ metodoPago.Nombre }} </h3>
                                                        <p class="size-color">{{ metodoPago.Descripcion }}</p>
                                                        <p class="size-color">
                                                            Estatus pago:
                                                            <span class="badge text-bg-warning" v-if="objPedido.EstatusPago=='Pendiente'">{{objPedido.EstatusPago}}</span>
                                                            <span class="badge text-bg-success" v-if="objPedido.EstatusPago=='Pagado'">{{objPedido.EstatusPago}}</span>
                                                            <span class="badge text-bg-danger"  v-if="objPedido.EstatusPago=='Cancelado'">{{objPedido.EstatusPago}}</span>
                                                        </p>
                                                    </div>
                                                </div>

                                                <div class="row" v-if="metodoPago.IdMetodoPago == 3 || metodoPago.IdMetodoPago == 4">
                                                    <div class="col-6">
                                                        <a @click="viewTicketPago()" class="btn btn-primary">Ver ticket para pago</a>
                                                    </div>
                                                    <div v-if="objPedido.EstatusPago=='Pendiente'" class="col-6 text-end">
                                                        <a @click="evidenciasPago()" class="btn btn-secondary ms-3">Enviar evidencia</a>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="col mb-3">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <h5 class="card-title">Direccion de envio</h5>
                                                <div v-if="viewDireccionEnv" class="products-added-list">
                                                    <div class="product-added" style="border-bottom: none; margin-bottom: 0rem">
                                                        <div class="details">
                                                            <h3 class="name"> 
                                                                <i v-if="direccionEnvio.TipoDireccion == 'Trabajo'" class="fal fa-suitcase fa-lg"></i>
                                                                <i v-if="direccionEnvio.TipoDireccion == 'Casa'" class="fal fa-house fa-lg"></i>
                                                                {{ direccionEnvio.Nombre }} 
                                                            </h3>
                                                            <p class="size-color">Calle {{ direccionEnvio.Calle }}, NoExt. {{ direccionEnvio.NoExt }}, 
                                                                <span v-if="direccionEnvio.NoInt != undefined && direccionEnvio.NoInt != null">No Int.{{ direccionEnvio.NoInt }}</span> Cruzamientos: {{ direccionEnvio.Cruzamiento1 }} 
                                                                <span v-if="direccionEnvio.Cruzamiento2 != undefined && direccionEnvio.Cruzamiento2 != null">y {{ direccionEnvio.Cruzamiento2 }},</span> Colonia: {{ direccionEnvio.Colonia }},
                                                                CP: {{ direccionEnvio.CodigoPostal }}, {{ direccionEnvio.Municipio }}, {{ direccionEnvio.Estado }}, {{ direccionEnvio.Pais }} 
                                                                <br> Referencias: {{ direccionEnvio.Referencias }}
                                                                <br>Telefono: {{ direccionEnvio.Telefono }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p v-else class="card-text">Sin datos envio</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col mb-3">
                                        <div class="card h-100">
                                            <div class="card-body">
                                                <h5 class="card-title">Facturación</h5>
                                                <div v-if="viewDataFactura" class="products-added-list">
                                                    <div class="product-added" style="border-bottom: none; margin-bottom: 0rem">
                                                        <div class="details">
                                                            <h3 class="name"> 
                                                                <i class="fal fa-university fa-lg"></i>
                                                                {{ direccionFactura.RazonSocial }}
                                                            </h3>
                                                            <p class="size-color">RFC: {{ direccionFactura.Rfc }} 
                                                                <br> Regimen Fiscal: {{ direccionFactura.RegimenFiscal }} 
                                                                <br>Direccion: {{ direccionFactura.Direccion }}, 
                                                                <br> Colonia: {{ direccionFactura.Colonia }}, 
                                                                CP: {{ direccionFactura.CodigoPostal }}, {{ direccionFactura.Municipio }}, {{ direccionFactura.Estado }} 
                                                                <br>Telefono: {{ direccionFactura.Telefono }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p v-else class="card-text">Pedido sin solicitud de facturación.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div class="row mt-2">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-body">
                                            <h5 class="card-title">Detalle del pedido</h5>

                                            <div class="table-responsive">
                                                <table class="table">
                                                    <thead>
                                                        <tr>
                                                            <th colspan="2">Producto</th>
                                                            <th>SKU</th>
                                                            <th class="text-center">Cantidad</th>
                                                            <th class="text-end">Precio</th>
                                                            <th class="text-end">Importe</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr v-for="(item,index) in objPedido.detalles" :key="index">
                                                            <td style="vertical-align: middle !important;">
                                                                <img :src="rutaProducto+item.IdProducto+'/'+item.Producto.ProductoxImagen.Imagen" alt="producto" class="img-responsive" width="90px">
                                                            </td>
                                                            <td style="vertical-align: middle !important;"><b> {{ item.Producto.producto.Nombre }}</b></td>
                                                            <td style="vertical-align: middle !important;">{{ item.Producto.producto.Codigo }}-<br>{{ item.Producto.talla.Clave }}-{{ item.Producto.color.Clave }}</td>
                                                            <td class="text-center" style="vertical-align: middle !important;">{{ item.Cantidad }}</td>
                                                            <td class="text-end" style="vertical-align: middle !important;">{{ $formatNumber(item.Precio, '$') }}</td>
                                                            <td class="text-end" style="vertical-align: middle !important;"><b>{{ $formatNumber(item.Importe, '$') }}</b></td>
                                                
                                                        </tr>
                                                    </tbody>
                                                    <tfoot class="text-end">
                                                        <tr>
                                                            <td colspan="4"></td>
                                                            <td><h6>Subtotal</h6></td>
                                                            <td>{{ $formatNumber(objPedido.Subtotal,'$') }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4"></td>
                                                            <td><h6>Descuentos</h6></td>
                                                            <td>{{ $formatNumber(objPedido.Descuento,'$') }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4"></td>
                                                            <td><h6>Envio</h6></td>
                                                            <td>{{ $formatNumber(objPedido.CostoEnvio,'$') }}</td>
                                                        </tr>
                                                        <tr>
                                                            <td colspan="4"></td>
                                                            <td><h5>Total</h5></td>
                                                            <td><h5>{{ $formatNumber(objPedido.GranTotal,'$') }}</h5></td>
                                                        </tr>
                                                    </tfoot>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </template>
                    </CLoader>
                    
                </div>
            </div>
        </main>

        <CModal :pConfigModal="ConfigModal" :poBtnSave="oBtnSave">
            <template slot="Form">
                <viewTicketPago :pMetodoPago="metodoPago"/>
            </template>
        </CModal>

        <CModal :pConfigModal="ConfigModalEvi" :poBtnSave="oBtnSaveEvi">
            <template slot="Form">
                <FormEvidenciaPago :poBtnSave="oBtnSaveEvi" :pPedido="objPedido"/>
            </template>
        </CModal>

    </div>
  
</template>

<script>
import CLoader from '@/components/CLoader.vue';
import CModal from '@/components/CModal.vue';
import viewTicketPago from './componentes/viewTicketPago.vue';
import FormEvidenciaPago from './componentes/FormEvidenciaPago.vue';
import StaticComboBox from '@/helpers/StaticComboBox';

const EmitEjecuta = 'seccionDetPedidosCli';
const EmitEjecuta2 = 'seccionEvidenciaPago';

export default {
    name: 'ViewDetallePedido',
    props:['Id'],
    components:{
        CLoader,
        CModal,
        viewTicketPago,
        FormEvidenciaPago
    },

    data() {
        return {
            rutaProducto: '',
            objPedido:{
                Folio:'',
                Estatus:''
            },

            ConfigLoad:{
                ShowLoader: false,
                ClassLoad: false,
            },

            metodoPago:{
                IdMetodoPago:0,
                Nombre: "",
                Descripcion: "",
                Tipo: 0,
                Disponible:0,
                DatosBanco:""
            },
            viewDireccionEnv: false,
            direccionEnvio: {

            },

            viewDataFactura: false,
            direccionFactura:{
                IdClienteDatoFiscal:0,
                IdCliente: 0,
                Nombres:"",
                RazonSocial:"",
                Rfc:"",
                Direccion:"",
                Colonia:"",
                Municipio:"",
                Estado:"",
                Pais:null,
                CodigoPostal:"",
                Telefono:"",
                RegimenFiscal: '',
            },

            ConfigModal:{
                ModalTitle: "Datos para pago",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecuta,
                ModalSize: 'md',
                ShowFooter: false,
            },

            oBtnSave: {
                Toast: 0,
                IsModal: true,
                DisableBtn: false,
                EmitSeccion: EmitEjecuta,
            },


            ConfigModalEvi:{
                ModalTitle: "Evidencias de pago",
                ModalNameId: 'ModalForm',
                EmitSeccion: EmitEjecuta2,
                ModalSize: 'md',
                ShowFooter: false,
                obj:{}
            },

            oBtnSaveEvi: {
                Toast: 0,
                IsModal: true,
                DisableBtn: false,
                EmitSeccion: EmitEjecuta2,
            },
        };
    },

    created() {
        this.bus.$off('NewModal_'+EmitEjecuta);
        this.bus.$off('HideFooter_'+EmitEjecuta);
    },
    mounted() {
        this.bus.$emit('cambiaLogoNegro');
        window.scrollTo(0, 0);

        if(this.Id != undefined  && this.Id != null){
            this.recoverPedido();
        }
        
    },

    methods: {

        async recoverPedido() {
        
            this.ConfigLoad.ShowLoader = true;

            await this.$http.get("pedidos/"+this.Id)
            .then((res) => {
                //console.log(res);
                this.objPedido              = res.data.data;
                this.rutaProducto           = res.data.rutaFile;

                // INFORMACION DE PAGO
                if(this.objPedido.IdMetodoPago == 1 || this.objPedido.IdMetodoPago == 3 || this.objPedido.IdMetodoPago == 4 ) {
                    if(this.objPedido.forma_pago != null && this.objPedido.forma_pago != undefined){
                        this.metodoPago = this.objPedido.forma_pago;
                    }
                }

                // DIRECCION DE ENVIO
                if(this.objPedido.DirEnvio != undefined && this.objPedido.DirEnvio != null ) {
                    this.viewDireccionEnv = true;
                    this.direccionEnvio = JSON.parse(this.objPedido.DirEnvio);
                }

                // DATOS DE FACTURACION
                if(this.objPedido.isFactura != undefined && this.objPedido.isFactura != null && parseInt(this.objPedido.isFactura) > 0) {
                    this.viewDataFactura = true;
                    this.direccionFactura = JSON.parse(this.objPedido.DirFactura);

                    let regTem = StaticComboBox.RegimenesFiscales.filter(reg => reg.id == this.direccionFactura.RegimenFiscal);
                    if(regTem.length > 0){
                        this.direccionFactura.RegimenFiscal = regTem[0].label;
                    }
                }

                
            }).finally(()=>{
                this.ConfigLoad.ShowLoader = false;
            });

            
        },

        goHome(){
            this.$router.push({name:'principal',params:{} });
        },   
        
        goPedidos() {
            this.$router.push({name:'profile',params:{pOrigen:'detPedido'} });
        },

        viewTicketPago(){
            this.bus.$emit('NewModal_'+EmitEjecuta);
            this.bus.$emit('HideFooter_'+EmitEjecuta,false);
        },

        evidenciasPago() {
            this.bus.$emit('NewModal_'+EmitEjecuta2,this.objPedido.IdPedido, this.objPedido);
            //this.bus.$emit('HideFooter_'+EmitEjecuta2, false);
        }
    },
};
</script>